import * as yup from "yup";
import dayjs from "dayjs";
const validationsForm =  yup.object().shape({
  new_name: yup.string()
  .required("Required"),
  emailaddress: yup.string()
  .email("Email address is not valid")
  .required("Required"),
  new_rmecode: yup
  .string()
  .required("Required"),
  new_lastworkingday: yup.
  string()
  .required("Required")
  .test('maxDate', 'Date must be within 2 months from now', function (value) {
    const inputDate = dayjs(value);
    const maxDate = dayjs().add(2, 'month');
    return inputDate.isBefore(maxDate);
  })
  ,
  gtoken: yup.string()
  .required("Required"),
  new_nid: yup.string()
  .when('new_egyptian', {
        is: true,
        then:  schema => 
          schema.required("National ID is Required")
        .matches(/^\d{14}$/, 'National ID must be exactly 14 digits only'),
 
        otherwise: schema => schema.required("Passport is Required")
        .matches(/^[A-Za-z0-9]{6,20}$/, 'Passport must be alphanumeric and 6-20 characters long')
  }),
  attachments: yup.object().shape({
    data: yup.string(),
    documentbody: yup.string(),
    mimetype: yup.string(),
    isdocument: yup.boolean(),
    filename: yup.string(),
    filesize: yup.number(),
  }).nullable().required("Required")
  .test('fileSize', 'Attachment size exceeds 2MB', (value) => {
     return value.filesize <= Number(2 * 1024 * 1024);
  }),
});

export default validationsForm;
