import React from 'react';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import './Form.css'; 
import { useParams } from 'react-router-dom';
import { fromByteArray } from 'base64-js';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Autocomplete, Box, Modal, Fade,Backdrop, FormControlLabel,Checkbox,
  Typography, TextField, Button, MenuItem, Grid,Container,InputAdornment  } from '@mui/material';
 
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik,ErrorMessage,Field  } from 'formik';
// Formik
import GeneralvalidationsForm from "../1.General/ValidationSchema";
import PersonalvalidationsForm from "../2.Personal/ValidationSchemaMega";
import EduvalidationsForm from "../3.Edu/Edu/ValidationSchema";
import WorkExpvalidationsForm from "../3.Edu/Work Experience/ValidationSchema";
import LangvalidationsForm from "../3.Edu/Language/ValidationSchema";
import ExtraTrainvalidationsForm from "../3.Edu/Extra Training/ValidationSchema";
import BKGVerifyvalidationsForm from "../3b. Background Verification/ValidationSchema";
import AdditionalInfovalidationsForm from "../4. Additional Information/ValidationSchema";
// Tables
import UniversityTable from '../3.Edu/Edu/UniversityTable';
import WorkExpTable from '../3.Edu/Work Experience/WorkExpTable';
import LangTable from '../3.Edu/Language/LanguageTable';
import ExtraTrainTable from '../3.Edu/Extra Training/ExtraTrainTable';
import Header from '../Element/Header';
import ProgressBar from '../Element/ProgressBar';
const MegaProjectForm = ({tag}) => {
  const recaptchaRef = React.useRef(null);
  const CHARACTER_LIMIT_20 = 20;
  const MySwal = withReactContent(Swal);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['General', 'Personal','Professional Background', 'Background Verification',
  'Additional Information'];
  //Passport Details / Driving License / Military Service','Medical Check   'Corporate Records','Acheivements',
  const [JobNames, setJobNames] = React.useState([]);
  const {id} = useParams();
  const [LoadingAPI,setLoadingAPI]= React.useState(false);
  const [key, setKey] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());
  const [jobdefaultname,setJobDefaultName]= React.useState('');
  const [placeOfBirth, setPlaceOfBirth] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [city, setCity] = React.useState('');
  const [otherNationality, setOtherNationality] = React.useState('');
 

// Calculate the maximum date for the current year (December 31st of the current year)
 
 
  // General
  const formikGeneral = useFormik({
    initialValues: {
      new_expectedsalaryt:'',
      new_availabletotravel:null,
      new_ifyouwillingtotravel:'',
      new_howdidyouhearaboutrowad:'',
      new_doyoupossessavaliddrivinglicense:null,
      new_reason:'',
      new_jobnameid:{id:id ,name:jobdefaultname,jobnameid:"",site:""},
      new_dateavailabletxt:'',
      new_graduationyear:'',

    },
    validationSchema: GeneralvalidationsForm,
    onSubmit: () => {
       handleNext()
    },
  });

  // Personal
  const formikPersonal = useFormik({
    initialValues: {
      new_name: '',
      new_email:'',
      new_telephonenumber:'',
      new_birthdate:'',
      new_placeofbirthcountriesguid:{id:"",name:""}, // Country GUID
      new_homeaddress:'',
      new_gender:'',
      new_nationalityguid:{id:"",name:""},           // Nationality GUID
      new_homeaddressgovernrate:{id:"",name:""},    // City GUID
      new_onationalityguid:{id:"",name:""},          // Other Nationality GUID
      new_maritalstatus:'',
      new_religion:'',
      new_nationalid: '',
      new_dependents:'',
    },
    validationSchema: PersonalvalidationsForm,
    onSubmit: values => {
       handleNext()
    },
  });
  const TableSubmit =() =>{
      // if(listedudata.length>0){
            handleNext()
        // } else {
          
        // }
}
 
  // Education
  const formikEducation= useFormik({
    initialValues: {
      new_universitytxt: '',
      new_name: '',
      new_from: '',
      new_to: '', 
      new_degree: '',
      new_graderating: '',
      new_major: '',
      new_minor: '',
    },
    validationSchema: EduvalidationsForm,
    onSubmit: values => {
      const{new_from,new_to,...rest}=values 
      handleAdd({
        "new_from": new_from.$y+'-'+Number(new_from.$M+1)+'-'+new_from.$D,
        "new_to":  new_to.$y+'-'+Number(new_to.$M+1)+'-'+new_to.$D,
        ...rest
      },"Education");
      handleCloseEdu()
   },
  });
  const [listedudata, setListEduData] = React.useState([]);
  const [openAddEdu, setOpenAddEdu] = React.useState(false);
  const handleOpenEdu = () => setOpenAddEdu(true);
  const handleCloseEdu = () =>{
    setOpenAddEdu(false);
    formikEducation.resetForm()
  }
  // Work Experience
  const formikWorkExp= useFormik({    
    initialValues: {
      new_name: '',                          // Company
      new_field: '',                         // Field
      new_positiontitle: '',                 // Job Title
      new_projecttype: '',                   // Project Type
      new_salary: '',                        // Salary
      new_employedfrom: '',                  // From
      new_employedtotxt: '',                 // To
      new_responsibilitiesachievements: '',  // Resposibility & Acheivement
      new_reasonforleaving:'',               // Reason for Leaving
    },
    validationSchema: WorkExpvalidationsForm,
    onSubmit: values => {
      const{new_employedfrom,new_employedtotxt,...rest}=values
      handleAdd({
        "new_employedfrom": new_employedfrom.$y+'-'+Number(new_employedfrom.$M+1)+'-'+new_employedfrom.$D,
        "new_employedtotxt":  new_employedtotxt.$y+'-'+Number(new_employedtotxt.$M+1)+'-'+new_employedtotxt.$D,
        ...rest
      },"Work Experience");
      handleCloseWorkExp()
  
    },
    }
  );
  const [listWorkExpdata , setListWorkExpData] = React.useState([]);
  const [openAddWorkExp, setOpenAddWorkExp] = React.useState(false);
  const handleOpenWorkExp = () => setOpenAddWorkExp(true);
  const handleCloseWorkExp  = () => {
    setOpenAddWorkExp(false)
    formikWorkExp.resetForm() 
  };

  // Language
  const formikLang= useFormik({
    initialValues:{
      new_languaget:{name:'',value:''}, 
      new_understandt:{name:'',value:''},
      new_speakt:{name:'',value:''},
      new_readwritet:{name:'',value:''},
    },
    validationSchema:LangvalidationsForm,
    onSubmit: values => {
      handleAdd(values,"Language");
      handleCloseLang()
  
    },
  });
  const [listlangdata, setListLangData] = React.useState([]);
  const [openAddLang, setOpenAddLang] = React.useState(false);
  const handleOpenLang = () => setOpenAddLang(true);
  const handleCloseLang = () => {
    setOpenAddLang(false)
    formikLang.resetForm()
  };
  // Extra Training
  const formikTraining= useFormik({
    initialValues:{
      new_name:'',                                      // Training Program / Course Name"
      new_centerinstitutecompany:'',                    // Center/ Institute / Company
      new_periodhours:'',                               // Period (Hours)
      new_fromdate:'',                                  // From
      new_todate:'',                                    // To
      new_descriptionobjectivecontentacquiredskills:'', // Description
    },
    validationSchema:ExtraTrainvalidationsForm,
    onSubmit: values => {
      const{new_fromdate,new_todate,...rest}=values
      handleAdd({
        "new_fromdate": new_fromdate.$y+'-'+Number(new_fromdate.$M+1)+'-'+new_fromdate.$D,
        "new_todate":  new_todate.$y+'-'+Number(new_todate.$M+1)+'-'+new_todate.$D,
        ...rest
      },"Extra Training");
      handleCloseTrain()},
  });
  const [listextratraindata, setListExtraTrainData] = React.useState([]);
  const [openAddTrain, setOpenAddTrain] = React.useState(false);
  const handleOpenTrain = () => setOpenAddTrain(true);
  const handleCloseTrain = () => {setOpenAddTrain(false)
    formikTraining.resetForm() 
  };

   // Background Verification
 // Additional Information
 const formikBackgroundVerif= useFormik({
  initialValues:{
    // Corporate Record
    new_haveyoueverbeenappliedforrowadbefore: null,     // Have you ever been Applied for rowad before
    new_ifyespleasegivedetails: '' ,                  // If Yes Please give details When Where How Result
    new_haveyoueverbeenemployedbyrowad: null,           // Have you ever been employd by rowad before
    new_ifyespleasegivedetailswhowherewhen:'',        // Position
    new_crsite:'',                                    // Site
    new_doyouhaveanyrelativeshiredbyanyofrowadco:null,  // Do you have any relatives Hired by any of ROWAD Co?
    new_ifyespleasegivedetailswhowherewhenrelativ:'', // If Yes Please give details Who, Where, When
  
    // Medical Check
    new_didyouoperateanysurgeries:null,                 // Did you operate any surgeries ?
    new_doyouhaveanychronicdisease:null,                // Do you have any chronic disease?
    new_ifyespleasestate:'',                          // if yes please state
    new_iherebystatethatimmedicallyfit:"No",            // I hereby state that I’m medically fit and free from infectious diseases and all that may hinder the implementation of the contractual tasks.
    new_iconfirmiamawarethatimaybesubject:"No",         // I confirm I am aware that I may be subject, upon the employer’s request, to medical Examinations required b
    new_iundertaketosubmitalltherequiredemploymen:"No", // I undertake to submit all the required employment documents including attested educational and experience certificates as pe

    // Reference Check
    // First Ref.
    new_fullname:null,     // Full Name
    new_company:null,      // Company
    new_relation:null,     // Relation
    new_position:null,     // Position
    new_contactphone:null, // Contact Phone
    // Second Ref.
    new_new_rf2fullname:null,     // Full Name
    new_rf2company:null,      // Company
    new_rf2relation:null,     // Relation
    new_rf2position:null,     // Position
    new_rf2contactphone:null, // Contact Phone
  
  },
 validationSchema:BKGVerifyvalidationsForm,
  onSubmit: values => 
  {
    handleNext()
  },
});

const encodeFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const base64String = fromByteArray(new Uint8Array(arrayBuffer));
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};
  // Additional Information
  const formikAdditionalInfo= useFormik({
    initialValues:{
      // Passport
      new_passportnumber:null,        // Passport ID
      new_issuingplace:null,          // Issuing Place
      new_professioninpassport:null,  // Profession In Passport
      new_issuingdate:null,           // Issuing Date
      new_psprtexpirydate:null,       // Expiry Date

      // Driving License
      new_drivinglicensenumber:null,   // Driving License Number
      new_dlexpirydate:null,           // Expiry Date

      // Military Services
      new_doyouhaveanyfurthermilitaryobligations:false,
      new_from:null,   
      new_to:null,
 
      // Achievements
      new_listouttheextrasocialactivities:null,  // List out the extra social activities
      new_qualitiesattributes:null,              // What quality traits do you
      new_careergoals:null,                      // career goals
      new_additionalinformation:null,            // Additional information

      //Recaptcha
      gtoken:null,            // Recaptcha token
      // Attachement
      attachment:null,
    
    },
    validationSchema:AdditionalInfovalidationsForm,
    onSubmit: values => 
    {
      const eduArray = Object.values(listedudata);
      // Remove the 'id' property from each object in the array
      const edu = eduArray.map(eduObj => {
        const { id, ...rest } = eduObj;
        return rest;
      });
      const workArray = Object.values(listWorkExpdata);
      // Remove the 'id' property from each object in the array
      const work = workArray.map(eduObj => {
        const { id, ...rest } = eduObj;
        return rest;
      });
      const langArray = Object.values(listlangdata);
      // Remove the 'id' property from each object in the array
      const language = langArray.map(eduObj => {
        const { new_languaget,new_understandt,new_speakt,new_readwritet } = eduObj;
        return {
          new_languaget:new_languaget.name,
          new_understandt:new_understandt.name,
          new_speakt:new_speakt.name,
          new_readwritet:new_readwritet.name
        };
      });
      const trainArray = Object.values(listextratraindata);
      // Remove the 'id' property from each object in the array
      const training = trainArray.map(eduObj => {
        const { id, ...rest } = eduObj;
        return rest;
      });
      const {new_contactphone,new_rf2contactphone,...restBackgroundVerif}= formikBackgroundVerif.values;
      const{
        new_issuingdate,
        attachment,
        new_psprtexpirydate,
        new_dlexpirydate,
        new_from,
        new_to,
         ...restAdditionalInfo} = values;
      // axios
 
     const {new_jobnameid,new_expectedsalaryt,new_graduationyear,new_ifyouwillingtotravel, ...restGeneral} = formikGeneral.values;
     const {new_placeofbirthcountriesguid,new_telephonenumber,new_nationalityguid,new_nationalid,
      new_birthdate,new_dependents, 
      new_onationalityguid,new_homeaddressgovernrate,...restPersonal} = formikPersonal.values;
      encodeFileToBase64(attachment)
      .then((base64String) => {
        // Example: Send send data and base64String to an API endpoint
        setLoadingAPI(true)
        axios.post(`${process.env.REACT_APP_Back_URL}/careers`,{
          "job":{
            ...restGeneral,  
            ...restPersonal,
            ...restBackgroundVerif,
            "new_ifyouwillingtotravel":formikGeneral.values.new_ifyouwillingtotravel,
            "new_birthdate":dayjs(new_birthdate).format('YYYY-MM-DD'),
            "new_dependents":new_dependents.toString(),
            "new_contactphone":new_contactphone?.toString(),
            "new_rf2contactphone":new_rf2contactphone?.toString(),
            "new_isdraft": false,
            // "new_HiringRequestId@odata.bind": `/new_hiringrequests(${new_jobnameid.id})`,
            "new_sitestring": new_jobnameid.site,
            "new_pleasesepcify": new_jobnameid.id + ' | ' + new_jobnameid.name,
            "new_expectedsalaryt":new_expectedsalaryt.toString(),
            "new_telephonenumber":new_telephonenumber.toString(),
            "new_nationalid":new_nationalid.toString(),
            // "new_jobname@odata.bind": `/new_jobnames(${new_jobnameid.jobnameid})`,
            "new_PlaceOfBirthcountries@odata.bind":`/new_countrieses(${new_placeofbirthcountriesguid.id})`,
            "new_Nationality@odata.bind": `/new_nationalitieses(${new_nationalityguid.id})`,
            ...(new_onationalityguid?.id !== null &&  new_onationalityguid.id !==""? 
            { "new_ONationality@odata.bind": `/new_nationalitieses(${new_nationalityguid.id})` } : {}),
            "new_HomeAddressgovernrate@odata.bind": `/new_citieses(${new_homeaddressgovernrate.id})`,
            "new_from":
            new_from !== null &&dayjs(new_from).isValid
            ?  dayjs(new_from).format('YYYY-MM-DD')
            : null,
            "new_graduationyear":new Date(new_graduationyear).getFullYear().toString(),
            "new_to":
            new_to !== null&&dayjs(new_to).isValid
            ?  dayjs(new_to).format('YYYY-MM-DD')
            : null,
  
            "new_dlexpirydate":
            new_dlexpirydate !== null&&dayjs(new_dlexpirydate).isValid
            ?  dayjs(new_dlexpirydate).format('YYYY-MM-DD')
            : null,
  
            "new_issuingdate":
            new_issuingdate !== null&&dayjs(new_issuingdate).isValid
            ?  dayjs(new_issuingdate).format('YYYY-MM-DD')
            : null,
  
            "new_psprtexpirydate":
            new_psprtexpirydate !== null&&dayjs(new_psprtexpirydate).isValid
            ?  dayjs(new_psprtexpirydate).format('YYYY-MM-DD')
            : null,
            ...restAdditionalInfo,
            },
            "attachment":{
            "isdocument": true,
            "mimetype": "application/pdf",
            "documentbody":base64String,
            "filename": formikPersonal.values.new_name+" CV .pdf",
            },
            edu,
            work,
            language,
            training
        }
          ).then(resp=>{
            setLoadingAPI(false)
            recaptchaRef.current?.reset();
            formikAdditionalInfo.setFieldValue("gtoken",null)
          if(resp.status==200){
  
            MySwal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Submitted Successfully',
              showConfirmButton: false,
              timer: 1500
            }).then(
             setTimeout(()=> window.location.href ="https://rowad-rme.com",1500)
         
            )
          }
          }
          )      
          .catch(function (error) {  
            setLoadingAPI(false)
            recaptchaRef.current?.reset(); 
            MySwal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message
   
            });
           })
      })
      .catch((error) => {
        // Handle any errors that occurred during Base64 encoding
        console.error('Base64 encoding error:', error);
      });

       },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
 
  React.useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_Back_URL}/careers/hrequests${tag}`,
      `${process.env.REACT_APP_Back_URL}/careers/national`,
      `${process.env.REACT_APP_Back_URL}/careers/countries`,
      `${process.env.REACT_APP_Back_URL}/careers/cities`
    ]; 
    Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((data) => 
    {
      data.forEach((resp, index) => {
        if(index===0){
          let arr= resp.data.sort((a,b) => (a.new_jobnamestring.toUpperCase() > b.new_jobnamestring.toUpperCase()) ? 1 : ((b.new_jobnamestring.toUpperCase() > a.new_jobnamestring.toUpperCase()) ? -1 : 0))
          setJobNames(arr.map((row) =>{
           return { id : row.new_name, name : row.new_jobnamestring.replace(",", ""),jobnameid:row.new_name,site:row.new_sitestring}
          }))
         let deval = arr.filter((row) => row.new_name===id )[0]
         if(deval != null){
          formikGeneral.setFieldValue("new_jobnameid",{id:deval.new_name,name:deval.new_jobnamestring.replace(",", ""),jobnameid:deval.new_name,site:deval.new_sitestring});
          }
        }
 
        if(index===1){
          let arr= resp.data.sort((a,b) => (a.new_nationality.toUpperCase() > b.new_nationality.toUpperCase()) ? 1 : ((b.new_nationality.toUpperCase() > a.new_nationality.toUpperCase()) ? -1 : 0))
          setNationality(arr.map((row) =>{
            return { id : row.new_nationalitiesid, name : row.new_nationality}
          }))
          setOtherNationality(arr.map((row) =>{
            return { id : row.new_nationalitiesid, name : row.new_nationality}
          }))
          }
        if(index===2){
          let arr= resp.data.sort((a,b) => (a.new_country.toUpperCase() > b.new_country.toUpperCase()) ? 1 : ((b.new_country.toUpperCase() > a.new_country.toUpperCase()) ? -1 : 0))
          setPlaceOfBirth(arr.map((row) =>{
            return { id : row.new_countriesid, name : row.new_country}
          }))
          }
        if(index===3){
          let arr= resp.data
            setCity(arr.map((row) =>{
              return { id : row.new_citiesid, name : row.new_city}
            }))
          }
      })
    }
    )
   }, []);
  const handleAdd = (values,tablename) => {
    if(tablename==="Education"){
      const newId = listedudata.length + 1;
      const newData = { id: newId,...values };
      setListEduData([...listedudata,newData]);
    } else if(tablename==="Work Experience"){
      const newId = listWorkExpdata.length + 1;
      const newData = { id: newId,...values };
      setListWorkExpData([...listWorkExpdata,newData]);
    } else if(tablename==="Language"){
      const newId = listlangdata.length + 1;
      const newData = { id: newId,...values };
      setListLangData([...listlangdata,newData]);
    } else if(tablename==="Extra Training"){
      const newId = listextratraindata.length + 1;
      const newData = { id: newId,...values };
      setListExtraTrainData([...listextratraindata,newData]);
    }
  };
  const handleEdit = (editedData,currentdata,tablename) => {
    const updatedData = currentdata.map((row) => {
      if (row.id === editedData.id) {
        return { ...row, ...editedData };
      }
      return row;
    });
    if(tablename==="Education"){
      setListEduData(updatedData);
    } else if(tablename==="Work Experience"){
      setListWorkExpData(updatedData);
    } else if(tablename==="Language"){
      setListLangData(updatedData);
    } else if(tablename==="Extra Training"){
 
      setListExtraTrainData(updatedData);
    }
  };          
  const handleDelete = (id,tablename) => {
    
        if(tablename==="Education"){
          const updatedData = listedudata.filter((row) => row.id !== id);
          setListEduData(updatedData);
    } else if(tablename==="Work Experience"){
      const updatedData = listWorkExpdata.filter((row) => row.id !== id);
      setListWorkExpData(updatedData);
    } else if(tablename==="Language"){
      const updatedData = listlangdata.filter((row) => row.id !== id);
      setListLangData(updatedData);
    } else if(tablename==="Extra Training"){
      const updatedData = listextratraindata.filter((row) => row.id !== id);
      setListExtraTrainData(updatedData);
    }
  };
  const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep -1);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextSubmit = () => {        
      switch (activeStep) {
        case 0:  formikGeneral.handleSubmit();    break;
        case 1:  formikPersonal.handleSubmit();   break;
        case 2:    
        if(listedudata.length>0)  {
          TableSubmit();
        }
          else{
            MySwal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Please enter at least 1 education"
  
            });
          }
          break;
        case 3:
          if(formikBackgroundVerif.isValid)  {
        formikBackgroundVerif.handleSubmit(); 
          }
          else{
            MySwal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Please fill all the required fields"
   
            });
          }
        break
        case 4:  formikAdditionalInfo.handleSubmit(); break;
      }                        
  };
 
   
  return (
      <Container >     
        <Box sx={{ bgcolor: 'rgb(255 255 255)', textAlign: 'left', padding: '30px' }}>
          <Header/>
          <ProgressBar  steps={steps} activeStep={activeStep} />
          <form noValidate autoComplete="off">
              {activeStep === 0 && (  
                <> 
                <Grid container spacing={2}>
     
                  <Grid item xs={11} sm={4}>
                    <Autocomplete
  
                    fullWidth
                      // disablePortal
                      id="combo-box-demo"
                      getOptionLabel={(option) => option.name }
                      
                      options={JobNames}
                      fullwidth
                      isOptionEqualToValue={(option,value)=>
                        option.name=== value.name
                      }
                      noOptionsText={"Not Found"}
                      renderOption={(props, option, index) => {
                        const key = `listItem-${index}-${option.id}`;
                        return (
                          <li {...props} key={key}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={ formikGeneral.values.new_jobnameid }
                      onChange={(e, value) =>{
                        if(value!=null){
                          formikGeneral.setFieldValue("new_jobnameid", value)
                        } else {
                          formikGeneral.setFieldValue("new_jobnameid", {id:'',name:'',jobnameid:'',site:''})
                        }
                      }}
                      renderInput={(params) => 
                      <TextField 
                      required
                      margin="normal"
                      fullWidth
                        {...params}      
                        name="new_jobnameid"
                        error={formikGeneral.errors.new_jobnameid?.id && formikGeneral.touched.new_jobnameid?.id}
                        helperText={formikGeneral.touched.new_jobnameid?.id && formikGeneral.errors.new_jobnameid?.id}
                        label="Position Applying for" />
                      }
                      />        
                  </Grid>        
    
                   <Grid item xs={11} sm={4}>
                      <TextField 
                      select
                      required
                      name="new_dateavailabletxt"
                      id="new_dateavailabletxt"
                      // autoFocus 
                      helperText={formikGeneral.touched.new_dateavailabletxt && formikGeneral.errors.new_dateavailabletxt}
                      error={ formikGeneral.touched.new_dateavailabletxt  && Boolean(formikGeneral.errors.new_dateavailabletxt)}
                      label="Expected Notice Period"
                      value={formikGeneral.values.new_dateavailabletxt}
                      onChange={formikGeneral.handleChange}
                
                      onBlur={formikGeneral.handleBlur}
                      fullWidth
                      margin="normal">
                          {[
                          {value:"1 Week",name:"1 Week"},
                          {value:"2 Week",name:"2 Week"},
                          {value:"3 Week",name:"3 Week"},
                          {value:"1 Month",name:"1 Month"},
                          {value:"2 Month",name:"2 Month"}
                        ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                      </TextField>
                      
                  </Grid> 
                  <Grid item xs={11} sm={4}>
                      <TextField  
                      required
                        label="Expected Salary (L.E)"
                        name="new_expectedsalaryt"
                        value={formikGeneral.values.new_expectedsalaryt}
                        onChange={formikGeneral.handleChange}
                        onBlur={formikGeneral.handleBlur}
 
                        onInput={(e) => {
                          let numericValue = e.target.value;
                          numericValue = numericValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      
                          // Check if the numeric value exceeds the max value (99999999)
                          if (numericValue.length > 8) {
                            numericValue = numericValue.slice(0, 8); // Truncate the value to 8 digits
                          }
                      
                          e.target.value = numericValue; // Assign the updated value back to the input field
                        }}
                        helperText={formikGeneral.touched.new_expectedsalaryt && formikGeneral.errors.new_expectedsalaryt}
                        error={ formikGeneral.touched.new_expectedsalaryt  && Boolean(formikGeneral.errors.new_expectedsalaryt)}
    
                        fullWidth
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                            e.preventDefault()
                          }
                        }}
                        margin="normal"
                        InputProps={{
                          endAdornment:<InputAdornment position="start">L.E</InputAdornment>
                        }}
                      />
                  </Grid>
                  <Grid item xs={11} sm={4}>
                    <TextField required
                      name="new_availabletotravel"
                      select
                      label="Willing to travel?"
                      value={formikGeneral.values.new_availabletotravel}
                      onChange={
                        (value) => {
                
                          if(value.target.value){
                            formikGeneral.setFieldValue('new_availabletotravel',  true )
                       
                          }  else {
                            formikGeneral.setFieldValue('new_availabletotravel',  false )
                            formikGeneral.setFieldValue('new_ifyouwillingtotravel','')
                          }
                        }
                      }
                      fullWidth
                      margin="normal"
                      onBlur={formikGeneral.handleBlur}
                      helperText={formikGeneral.touched.new_availabletotravel && formikGeneral.errors.new_availabletotravel}
                      error={ formikGeneral.touched.new_availabletotravel  && Boolean(formikGeneral.errors.new_availabletotravel)}
                      id="new_availabletotravel"
                    >{
                      [
                        {value:true,name:"Yes"},
                        {value:false,name:"No"}
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>   
                  </Grid>
                  {formikGeneral.values.new_availabletotravel &&(
                  <Grid item xs={11} sm={4}>
                    <TextField required
                      name="new_ifyouwillingtotravel"
                      id="new_ifyouwillingtotravel"
                      onChange={formikGeneral.handleChange}   
                      value={formikGeneral.values.new_ifyouwillingtotravel}
                      onBlur={formikGeneral.handleBlur}
                      helperText={formikGeneral.touched.new_ifyouwillingtotravel && formikGeneral.errors.new_ifyouwillingtotravel}
                      error={ formikGeneral.touched.new_ifyouwillingtotravel  && Boolean(formikGeneral.errors.new_ifyouwillingtotravel)}
                      label="If you willing to travel, please state"
                      select
                      fullWidth
                      margin="normal"
                    >{
                      [
                        {value:"Domestic",name:"Domestic"},
                        {value:"International",name:"International"},
                        {value:"Both",name:"Both"}
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>   
                  </Grid>
                  )}
   
                  <Grid item xs={11} sm={4}>
                    <TextField required
                        name="new_howdidyouhearaboutrowad"
                        select
                        label="Where did you hear about Us"
                        value={formikGeneral.values.new_howdidyouhearaboutrowad}
                        onChange={formikGeneral.handleChange}
                        fullWidth
                        margin="normal"
                        onBlur={formikGeneral.handleBlur}
                        helperText={formikGeneral.touched.new_howdidyouhearaboutrowad && formikGeneral.errors.new_howdidyouhearaboutrowad}
                        error={ formikGeneral.touched.new_howdidyouhearaboutrowad  && Boolean(formikGeneral.errors.new_howdidyouhearaboutrowad)}
                        id="new_howdidyouhearaboutrowad"
                      >{
                        [
                          {value:100000000,name:"University"},
                          {value:100000001,name:"Advertisement"},
                          {value:100000002,name:"Recruitment Fair"},
                          {value:100000003,name:"Friends"},
                          {value:100000004,name:"Field"},
                          {value:100000005,name:"Social Media"},
                          {value:100000006,name:"Other"}].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </TextField>   
                  </Grid>
                  <Grid item xs={11} sm={4}>
                    <TextField required
                          name="new_doyoupossessavaliddrivinglicense"
                          select
                          label="Do you Hold a Valid Driving License? "
                          value={formikGeneral.values.new_doyoupossessavaliddrivinglicense}
                          onChange={formikGeneral.handleChange}
                          fullWidth
                          margin="normal"
                          onBlur={formikGeneral.handleBlur}
                          helperText={formikGeneral.touched.new_doyoupossessavaliddrivinglicense && formikGeneral.errors.new_doyoupossessavaliddrivinglicense}
                          error={ formikGeneral.touched.new_doyoupossessavaliddrivinglicense  && Boolean(formikGeneral.errors.new_doyoupossessavaliddrivinglicense)}
                          id="new_doyoupossessavaliddrivinglicense"
                        >{
                          [
                            {value:true,name:"Yes"},
                            {value:false,name:"No"}
                          ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                    </TextField>   
                  </Grid>
                  <Grid item xs={11} sm={4}>
                    <TextField
                      required
                      name="new_reason"
                      select
                      label="What is your Military status?"
                      value={formikGeneral.values.new_reason}
                      onChange={formikGeneral.handleChange}
                      fullWidth
                      margin="normal"
                      onBlur={formikGeneral.handleBlur}
                      id="new_reason"
                      helperText={formikGeneral.touched.new_reason && formikGeneral.errors.new_reason}
                      error={ formikGeneral.touched.new_reason  && Boolean(formikGeneral.errors.new_reason)}

                      >
                        {[
                          {value:"Not Applicable",name:"Not Applicable"},
                          {value:"Completed ",name:"Completed "},
                          {value:"Exempted",name:"Exempted"},
                          {value:"Serving",name:"Serving"},
                          {value:"Postponed",name:"Postponed"}
                        ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                    </TextField>   
                  </Grid>
                  <Grid item xs={11} sm={4}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                        <DemoContainer  components={['DatePicker']} >
                          <DatePicker
                          label="Graduation year"
                          maxDate={dayjs(new Date ())}
                          openTo="year"
                          views={['year']}
                          yearsOrder="desc"
                         className="css-1u0h3mu"
                          name="new_graduationyear"
                            id="new_graduationyear"  
                          value={ formikGeneral.values.new_graduationyear || null}
                           onChange={
                            (date) => formikGeneral.setFieldValue('new_graduationyear',  date ) }
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              required:true,
                            //  disabled:true,
                              error: formikGeneral.touched.new_graduationyear  && Boolean(formikGeneral.errors.new_graduationyear),
                              helperText:formikGeneral.touched.new_graduationyear && formikGeneral.errors.new_graduationyear
                            },
                            }}
                            />
                          </DemoContainer>
                      </LocalizationProvider>
                      </Grid>  
                </Grid>
            
                </>
              )}
              {activeStep === 1 && (
                <>
                 <Grid container spacing={2}>
                      <Grid item xs={11} sm={4}>
                      <TextField 
                            required
                            name="new_name"
                            id="new_name"
                            // autoFocus 
                            inputProps={{
                              maxlength: 100
                            }}
                            helperText={formikPersonal.touched.new_name && formikPersonal.errors.new_name}
                            error={ formikPersonal.touched.new_name  && Boolean(formikPersonal.errors.new_name)}
                            label="Full Name"
                            value={formikPersonal.values.new_name}
                            onChange={formikPersonal.handleChange}
                            onBlur={formikPersonal.handleBlur}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField 
                            required
                            name="new_email"
                            id="new_email"
                            inputProps={{
                              maxlength: 100
                            }}
                            // autoFocus 
                            helperText={formikPersonal.touched.new_email && formikPersonal.errors.new_email}
                            error={ formikPersonal.touched.new_email  && Boolean(formikPersonal.errors.new_email)}
                            label="Email"
                            value={formikPersonal.values.new_email}
                            onChange={formikPersonal.handleChange}
                            onBlur={formikPersonal.handleBlur}
                            fullWidth
                            margin="normal"
                        />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField 
                            required
                            name="new_telephonenumber"
                            id="new_telephonenumber"
                            // autoFocus 
                            helperText={formikPersonal.touched.new_telephonenumber && formikPersonal.errors.new_telephonenumber}
                            error={ formikPersonal.touched.new_telephonenumber  && Boolean(formikPersonal.errors.new_telephonenumber)}
                            label="Telephone"
                            value={formikPersonal.values.new_telephonenumber}
                            onChange={formikPersonal.handleChange}
                            onBlur={formikPersonal.handleBlur}
                            onInput={(e) => {
                              let numericValue = e.target.value;
                              numericValue = numericValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                           
                              // Check if the numeric value exceeds the max value (99999999)
                              if (numericValue.length > 20) {
                                numericValue = numericValue.slice(0, 20); // Truncate the value to 8 digits
                              }
                          
                              e.target.value = numericValue; // Assign the updated value back to the input field
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                e.preventDefault()
                              }
                            }}
                            fullWidth
                            margin="normal"
                        />
                       </Grid>
                      <Grid item xs={11} sm={4}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                        <DemoContainer  components={['DatePicker']} >
                          <DatePicker
                          label="Birth Date"
 
                           margin="normal"
                          value={ formikPersonal.values.new_birthdate || null}
                           onChange={(date) => formikPersonal.setFieldValue('new_birthdate',  date )}
                          format="YYYY-MM-DD"
                          slotProps={{
                            textField: {
                              // format:'YYYY-MM-DD',
                              // disabled:true,
                              fullWidth: true,
                              required:true,
                              error: formikPersonal.touched.new_birthdate  && Boolean(formikPersonal.errors.new_birthdate),
                              helperText:formikPersonal.touched.new_birthdate && formikPersonal.errors.new_birthdate
                            },
                            }}
                            />
                          </DemoContainer>
                      </LocalizationProvider>
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <Autocomplete
                             // disablePortal
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.name   }
                            options={placeOfBirth}
                            isOptionEqualToValue={(option,value)=>
                              option.name=== value.name
                            }
                            noOptionsText={"Not Found"}
                            renderOption={(props, option, index) => {
                              const key = `listItem-${index}-${option.id}`;
                              return (
                                <li {...props} key={key}>
                                  {option.name}
                                </li>
                              );
                            }}
                            // value={placeOfBirthID}
                            // onChange={(event, value) => {
                            //   setplaceOfBirthID(value);  
                            // }}
                            // renderInput={(params) => <TextField  {...params}  label="Place of Birth (Country)" />}
                            // />
                            value={ formikPersonal.values.new_placeofbirthcountriesguid }
                            onChange={(e, value) =>{
                              if(value!=null){
                                formikPersonal.setFieldValue("new_placeofbirthcountriesguid", value)
                              } else {
                                formikPersonal.setFieldValue("new_placeofbirthcountriesguid", {id:'',name:''})
                              }
                            }}
                            renderInput={(params) => 
                            <TextField 
                            fullWidth
                            margin="normal"
                            required
                              {...params}      
                              name="new_placeofbirthcountriesguid"
                              error={formikPersonal.errors.new_placeofbirthcountriesguid?.id && formikPersonal.touched.new_placeofbirthcountriesguid?.id}
                              helperText={formikPersonal.touched.new_placeofbirthcountriesguid?.id && formikPersonal.errors.new_placeofbirthcountriesguid?.id}
                              label="Place of Birth (Country)" />
                            }
                            />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField
                          required
                          label="Home Address"
                          id='new_homeaddress'
                          name="new_homeaddress"
                          value={formikPersonal.values.new_homeaddress}
                          onChange={formikPersonal.handleChange}
                          onBlur={formikPersonal.handleBlur}
                          helperText={formikPersonal.touched.new_homeaddress && formikPersonal.errors.new_homeaddress}
                          error={ formikPersonal.touched.new_homeaddress  && Boolean(formikPersonal.errors.new_homeaddress)}                  
                          fullWidth
                          inputProps={{
                            maxlength: 200
                          }}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField required
                          name="new_gender"
                          select
                          label="Gender"
                          value={formikPersonal.values.new_gender}
                          onChange={formikPersonal.handleChange}
                          fullWidth
                          margin="normal"
                          onBlur={formikPersonal.handleBlur}
                          helperText={formikPersonal.touched.new_gender && formikPersonal.errors.new_gender}
                          error={ formikPersonal.touched.new_gender  && Boolean(formikPersonal.errors.new_gender)}
                          id="new_gender"
                        >{
                          [
                            {value:100000000,name:"Male"},
                            {value:100000001,name:"Female"}
                          ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>  
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <Autocomplete
                            required
 
                            getOptionLabel={(option) => option.name }
                            options={nationality}
  
                            isOptionEqualToValue={(option,value)=>
                              option.name=== value.name
                            }
                            noOptionsText={"Not Found"}
                            renderOption={(props, option, index) => {
                              const key = `listItem-${index}-${option.id}`;
                              return (
                                <li {...props} key={key}>
                                  {option.name}
                                </li>
                              );
                            }}
                            
                            id="combo-box-demo"
                            value={ formikPersonal.values.new_nationalityguid }
                            onChange={(e, value) =>{
                              if(value!=null){
                                formikPersonal.setFieldValue("new_nationalityguid", value)
                              } else {
                                formikPersonal.setFieldValue("new_nationalityguid", {id:'',name:''})
                              }
                            }}
                            renderInput={(params) => 
                            <TextField 
                            required
                              {...params}      
                              name="new_jobnameid"
                              fullWidth
                              margin="normal"
                              error={formikPersonal.errors.new_nationalityguid?.id && formikPersonal.touched.new_nationalityguid?.id}
                              helperText={formikPersonal.touched.new_nationalityguid?.id && formikPersonal.errors.new_nationalityguid?.id}
                              label="Nationality" />
                            }
                            />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                      <Autocomplete
 
                          // disablePortal
                          id="new_homeaddressgovernrate1                                                                                                                    "
                          getOptionLabel={(option) => option.name }
                          options={city}
         
                          isOptionEqualToValue={(option,value)=>
                            option.name=== value.name
                          }
                          noOptionsText={"Not Found"}
                          renderOption={(props, option, index) => {
                            const key = `listItem-${index}-${option.id}`;
                            return (
                              <li {...props} key={key}>
                                {option.name}
                              </li>
                             );
                          }}
                          value={ formikPersonal.values.new_homeaddressgovernrate }
                          onChange={(e, value) =>{
                            if(value!=null){
                              formikPersonal.setFieldValue("new_homeaddressgovernrate", value)
                            } else {
                              formikPersonal.setFieldValue("new_homeaddressgovernrate", {id:'',name:''})
                            }
                          }}
                          renderInput={(params) => 
                          <TextField 
                          required
                            {...params}      
                            name="new_homeaddressgovernrate"
                            error={formikPersonal.errors.new_homeaddressgovernrate?.id && formikPersonal.touched.new_homeaddressgovernrate?.id}
                            helperText={formikPersonal.touched.new_homeaddressgovernrate?.id && formikPersonal.errors.new_homeaddressgovernrate?.id}
                            label="City"
                            fullWidth
                            margin="normal"
                             />
                          }
                          />                     
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField 
                          required
                          name="new_nationalid"
                          id="new_nationalid"
                          label="National ID"
                          inputProps={{
                            maxLength: 14,
                            type: 'number',
                          }}
                          value={formikPersonal.values.new_nationalid}
                          onChange={formikPersonal.handleChange}
                          onBlur={formikPersonal.handleBlur}
                          helperText={formikPersonal.touched.new_nationalid && formikPersonal.errors.new_nationalid}
                          error={ formikPersonal.touched.new_nationalid  && Boolean(formikPersonal.errors.new_nationalid)}
                          fullWidth
                          margin="normal"
                          // type='number'
                          onKeyDown={(e) => {
                            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                              e.preventDefault()
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <Autocomplete
 
                          // disablePortal
                          id="combo-box-demo"
                          getOptionLabel={(option) => option.name }
                          options={otherNationality}
                          fullwidth
                          isOptionEqualToValue={(option,value)=>
                            option.name=== value.name
                          }
                          noOptionsText={"Not Found"}
                          renderOption={(props, option, index) => {
                            const key = `listItem-${index}-${option.id}`;
                            return (
                              <li {...props} key={key}>
                                {option.name}
                              </li>
                            );
                          }}
                          value={ formikPersonal.values.new_onationalityguid }
                          onChange={(e, value) =>{
                            if(value!=null){
                              formikPersonal.setFieldValue("new_onationalityguid", value)
                            } else {
                              formikPersonal.setFieldValue("new_onationalityguid", {id:'',name:''})
                            }
                          }}
                          renderInput={(params) => 
                          <TextField 
    
                            {...params}      
                            name="new_onationalityguid"
                            error={formikPersonal.errors.new_onationalityguid?.id && formikPersonal.touched.new_onationalityguid?.id}
                            helperText={formikPersonal.touched.new_onationalityguid?.id && formikPersonal.errors.new_onationalityguid?.id}
                            label="Other Nationality" 
                            margin="normal"
                            fullWidth
                            />
                            

                          }
                          />
                      </Grid>
                      <Grid item xs={11} sm={4}>

                      <TextField 
                        select
                        name="new_religion"
                        label="Religon"
                        value={formikPersonal.values.new_religion}
                        onChange={formikPersonal.handleChange}
                        fullWidth
                        margin="normal"
                        onBlur={formikPersonal.handleBlur}
                        helperText={formikPersonal.touched.new_religion && formikPersonal.errors.new_religion}
                        error={ formikPersonal.touched.new_religion  && Boolean(formikPersonal.errors.new_religion)}
                        id="new_religion">
                          {
                          [
                            {value:"Muslim",name:"Muslim"},
                            {value:"Christian",name:"Christian"}
                          ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
               
                      </Grid>
   
                      <Grid item xs={11} sm={4}>
                    <TextField required
                        name="new_maritalstatus"
                        select
                        label="Martial Status"
                        value={formikPersonal.values.new_maritalstatus}
                        onChange={formikPersonal.handleChange}
                        fullWidth
                        margin="normal"
                        onBlur={formikPersonal.handleBlur}
                        helperText={formikPersonal.touched.new_maritalstatus && formikPersonal.errors.new_maritalstatus}
                        error={ formikPersonal.touched.new_maritalstatus  && Boolean(formikPersonal.errors.new_maritalstatus)}
                        id="new_maritalstatus"
                      >{
                        [
                          {value:100000000,name:"Single"},
                          {value:100000001,name:"Married"},
                          {value:100000002,name:"Divorced"},
                          {value:100000003,name:"Widower"},

                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>             
                  </Grid>
                      <Grid item xs={11} sm={4}>
                      <TextField 
                        label="Number of Dependents"
                        type='number'
                        required
                        value={formikPersonal.values.new_dependents}
                        onChange={formikPersonal.handleChange}
                        fullWidth
                        margin="normal"
                        // InputProps={{ inputProps: { min: 0, max: 10 } }}
                        onInput={(e) => {
                          const numericValue = (e.target.value);
                      
                          // Check if the numeric value exceeds the max value (100)
                          if (!isNaN(numericValue) && numericValue > 100) {
                            e.target.value = "100";
                          }
                      
                          // Check if the numeric value is below the min value (0)
                          if (!isNaN(numericValue) && numericValue < 0) {
                            e.target.value = "0";
                          }
                        }}
                        onBlur={formikPersonal.handleBlur}
                        helperText={formikPersonal.touched.new_dependents && formikPersonal.errors.new_dependents}
                        error={ formikPersonal.touched.new_dependents  && Boolean(formikPersonal.errors.new_dependents)}
                        id="new_dependents"
                      />
                      </Grid>
                      </Grid>
             
                </>
              )}
              {activeStep === 2 && (
                <div>
                  
                  <h2><span style={{color:'red',paddingRight:'5px'}}>*</span>Education</h2>
                  <Button onClick={handleOpenEdu} variant="outlined">Add</Button>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openAddEdu}
                    onClose={handleCloseEdu}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={openAddEdu}>
                      <Box className='popupaddedit'>
                        <Typography id="transition-modal-title" variant="h4" component="h2">
                          Add Education
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_universitytxt"
                                label="University"
                                value={formikEducation.values.new_universitytxt}
                                onChange={formikEducation.handleChange}
                                inputProps={{
                                  maxlength: 200
                                }}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_universitytxt && formikEducation.errors.new_universitytxt}
                                error={ formikEducation.touched.new_universitytxt  && Boolean(formikEducation.errors.new_universitytxt)}
                                placeholder="University"
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_name"
                                label="Faculty"
                                value={formikEducation.values.new_name}
                                onChange={formikEducation.handleChange}
                                inputProps={{
                                  maxlength: 100
                                }}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_name && formikEducation.errors.new_name}
                                error={ formikEducation.touched.new_name  && Boolean(formikEducation.errors.new_name)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formikEducation.values.new_from || null}
                                onChange={(date) => formikEducation.setFieldValue('new_from',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    // disabled:true,
                                    required:true,
                                    error: formikEducation.touched.new_from  && Boolean(formikEducation.errors.new_from),
                                    helperText:formikEducation.touched.new_from && formikEducation.errors.new_from
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formikEducation.values.new_to || null}
                                onChange={(date) => formikEducation.setFieldValue('new_to',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    // disabled:true,
                                    required:true,
                                    error: formikEducation.touched.new_to  && Boolean(formikEducation.errors.new_to),
                                    helperText:formikEducation.touched.new_to && formikEducation.errors.new_to
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField required
                                  name="new_degree"
                                  select
                                  label="Degree"
                                  value={formikEducation.values.new_degree}
                                  onChange={formikEducation.handleChange}
                                  fullWidth
                                  margin="normal"
                                  onBlur={formikEducation.handleBlur}
                                  helperText={formikEducation.touched.new_degree && formikEducation.errors.new_degree}
                                  error={ formikEducation.touched.new_degree  && Boolean(formikEducation.errors.new_degree)}
                                  id="new_degree"
                                >{
                                  [
                                    {value:"Bachelor's degree",name:"Bachelor's degree"},
                                    {value:"Master's degree",name:"Master's degree"},
                                    {value:"Doctoral degree",name:"Doctoral degree"}
                                  ].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_graderating"
                                label="Grade/Rating"
                                value={formikEducation.values.new_graderating}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikEducation.touched.new_graderating && formikEducation.errors.new_graderating}
                                error={ formikEducation.touched.new_graderating  && Boolean(formikEducation.errors.new_graderating)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_major"
                                label="Major"
                                value={formikEducation.values.new_major}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikEducation.touched.new_major && formikEducation.errors.new_major}
                                error={ formikEducation.touched.new_major  && Boolean(formikEducation.errors.new_major)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_minor"
                                label="Minor"
                                value={formikEducation.values.new_minor}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_minor && formikEducation.errors.new_minor}
                                error={ formikEducation.touched.new_minor  && Boolean(formikEducation.errors.new_minor)}
                                margin="normal"                 
                                inputProps={{
                                  maxlength: 100
                                }}
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formikEducation.handleSubmit}>
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                  <UniversityTable data={listedudata} handleEdit={handleEdit} handleDelete={handleDelete}   /> 

                  <h2>Work Experience / Current Employer  </h2>
                  <Button onClick={handleOpenWorkExp} variant="outlined">Add</Button>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openAddWorkExp}
                    onClose={handleCloseWorkExp}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >

                    <Fade in={openAddWorkExp}>
                      <Box className='popupaddedit'>
                        <Typography id="transition-modal-title" variant="h4" component="h2">
                          Add Work Experience / Current Employer
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_name"
                                label="Company"
                                value={formikWorkExp.values.new_name}
                                onChange={formikWorkExp.handleChange}
                                onBlur={formikWorkExp.handleBlur}
                                helperText={formikWorkExp.touched.new_name && formikWorkExp.errors.new_name}
                                error={ formikWorkExp.touched.new_name  && Boolean(formikWorkExp.errors.new_name)}
                                inputProps={{
                                  maxlength: 100
                                }}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_field"
                                label="Field"
                                value={formikWorkExp.values.new_field}
                                onChange={formikWorkExp.handleChange}
                                onBlur={formikWorkExp.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikWorkExp.touched.new_field && formikWorkExp.errors.new_field}
                                error={ formikWorkExp.touched.new_field  && Boolean(formikWorkExp.errors.new_field)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_positiontitle"
                                label="Job Title"
                                value={formikWorkExp.values.new_positiontitle}
                                onChange={formikWorkExp.handleChange}
                                onBlur={formikWorkExp.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikWorkExp.touched.new_positiontitle && formikWorkExp.errors.new_positiontitle}
                                error={ formikWorkExp.touched.new_positiontitle  && Boolean(formikWorkExp.errors.new_positiontitle)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_projecttype"
                                label="Project Type"
                                value={formikWorkExp.values.new_projecttype}
                                onChange={formikWorkExp.handleChange}
                                inputProps={{
                                  maxlength: 100
                                }}
                                onBlur={formikWorkExp.handleBlur}
                                helperText={formikWorkExp.touched.new_projecttype && formikWorkExp.errors.new_projecttype}
                                error={ formikWorkExp.touched.new_projecttype  && Boolean(formikWorkExp.errors.new_projecttype)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_salary"
                                label="Salary"
                                value={formikWorkExp.values.new_salary}
                                onChange={formikWorkExp.handleChange}
                                onBlur={formikWorkExp.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikWorkExp.touched.new_salary && formikWorkExp.errors.new_salary}
                                error={ formikWorkExp.touched.new_salary  && Boolean(formikWorkExp.errors.new_salary)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                value={ formikWorkExp.values.new_employedfrom || null}
                                onChange={(date) => formikWorkExp.setFieldValue('new_employedfrom',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikWorkExp.touched.new_employedfrom  && Boolean(formikWorkExp.errors.new_employedfrom),
                                    helperText:formikWorkExp.touched.new_employedfrom && formikWorkExp.errors.new_employedfrom
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formikWorkExp.values.new_employedtotxt || null}
                                onChange={(date) => formikWorkExp.setFieldValue('new_employedtotxt',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikWorkExp.touched.new_employedtotxt  && Boolean(formikWorkExp.errors.new_employedtotxt),
                                    helperText:formikWorkExp.touched.new_employedtotxt && formikWorkExp.errors.new_employedtotxt
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_responsibilitiesachievements"
                                label="Resposibility & Acheivement"
                                value={formikWorkExp.values.new_responsibilitiesachievements}
                                onChange={formikWorkExp.handleChange}
                                onBlur={formikWorkExp.handleBlur}
                                inputProps={{
                                  maxlength: 2000
                                }}
                                helperText={formikWorkExp.touched.new_responsibilitiesachievements && formikWorkExp.errors.new_responsibilitiesachievements}
                                error={ formikWorkExp.touched.new_responsibilitiesachievements  && Boolean(formikWorkExp.errors.new_responsibilitiesachievements)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_reasonforleaving"
                                label="Reason for Leaving"
                                value={formikWorkExp.values.new_reasonforleaving}
                                onChange={formikWorkExp.handleChange}
                                inputProps={{
                                  maxlength: 200
                                }}
                                onBlur={formikWorkExp.handleBlur}
                                helperText={formikWorkExp.touched.new_reasonforleaving && formikWorkExp.errors.new_reasonforleaving}
                                error={ formikWorkExp.touched.new_reasonforleaving  && Boolean(formikWorkExp.errors.new_reasonforleaving)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formikWorkExp.handleSubmit}>
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                  <WorkExpTable data={listWorkExpdata} handleEdit={handleEdit} handleDelete={handleDelete}   /> 
                  
                  <h2>Languages</h2>
                  <Button onClick={handleOpenLang} variant="outlined">Add</Button>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openAddLang}
                    onClose={handleCloseLang}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={openAddLang}>
                      <Box className='popupaddedit'>
                        <Typography id="transition-modal-title" variant="h4" component="h2">
                          Add Language
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                                <TextField required
                                  name="new_languaget"
                                  select
                                  label="Language"
                                  value={formikLang.values.new_languaget.value}
                                  onChange={(e, value) =>{
 
                                    if(value!=null){
                                      formikLang.setFieldValue("new_languaget", {value:value.props.value,name:value.props.children})
                                    } else {
                                      formikLang.setFieldValue("new_languaget", {value:'',name:''})
                                    }
                                  }}
                                   
                                  fullWidth
                                  margin="normal"
                                  onBlur={formikLang.handleBlur}
                                  helperText={formikLang.touched.new_languaget?.name && formikLang.errors.new_languaget?.name}
                                  error={ formikLang.touched.new_languaget?.name  && Boolean(formikLang.errors.new_languaget?.name)}
                                  id="new_languaget"
                                >{
                                  [
                                    {value:100000000,name:"ENGLISH"},
                                    {value:100000001,name:"ARABIC"},
                                    {value:100000003,name:"CHINESE"},
                                    {value:100000009,name:"GERMAN"},
                                    {value:100000011,name:"FRENCH"},
                                    {value:100000004,name:"HINDI"},
                                    {value:100000005,name:"PORTUGUESE"},
                                    {value:100000007,name:"RUSSIAN"},
                                    {value:100000006,name:"BENGALI"},
                                    {value:100000008,name:"JAPANESE"},
                                    {value:100000010,name:"KOREAN"},
                                    {value:100000012,name:"TURKISH"},
                                    {value:100000013,name:"VIETNAMESE"}
                                  ].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>   
                            </Grid>
                              <Grid item xs={6} sm={4}>
                              <TextField required
                                    name="new_understandt"
                                    select
                                    label="Understanding"
                                    value={formikLang.values.new_understandt.value}
                                    onChange={(e, value) =>{
   
                                      if(value!=null){
                                        formikLang.setFieldValue("new_understandt", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formikLang.setFieldValue("new_understandt", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formikLang.handleBlur}
                                    helperText={formikLang.touched.new_understandt?.name && formikLang.errors.new_understandt?.name}
                                    error={ formikLang.touched.new_understandt?.name  && Boolean(formikLang.errors.new_understandt?.name)}
                                    
                                    id="new_degree"
                                  >{
                                    [
                                          {value:100000000,name:"Excellent"},
                                           {value:100000001,name:"Good"},
                                           {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField required
                                    name="new_speakt"
                                    select
                                    label="Speaking"
                                    value={formikLang.values.new_speakt.value}
                                    onChange={(e, value) =>{
                                      if(value!=null){
                                        formikLang.setFieldValue("new_speakt", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formikLang.setFieldValue("new_speakt", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formikLang.handleBlur}
                                    helperText={formikLang.touched.new_speakt?.name && formikLang.errors.new_speakt?.name}
                                    error={ formikLang.touched.new_speakt?.name  && Boolean(formikLang.errors.new_speakt?.name)} 
                                    id="new_speakt"
                                  >{
                                    [
                                      {value:100000000,name:"Excellent"},
                                      {value:100000001,name:"Good"},
                                      {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                      
                                    ))}
                                  </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField required
                                    name="new_readwritet"
                                    select
                                    label="Reading/Writing"
                                    value={formikLang.values.new_readwritet.value}
                                    onChange={(e, value) =>{
   
                                      if(value!=null){
                                        formikLang.setFieldValue("new_readwritet", {value:value.props.value,name:value.props.children})
                                      } else {
                                        formikLang.setFieldValue("new_readwritet", {value:'',name:''})
                                      }
                                    }}
                                    fullWidth
                                    margin="normal"
                                    onBlur={formikLang.handleBlur}
                                    helperText={formikLang.touched.new_readwritet?.name && formikLang.errors.new_readwritet?.name}
                                    error={ formikLang.touched.new_readwritet?.name  && Boolean(formikLang.errors.new_readwritet?.name)}
                                    id="new_readwritet"
                                  >{
                                    [
                                           {value:100000000,name:"Excellent"},
                                           {value:100000001,name:"Good"},
                                           {value:100000002,name:"Fair"}
                                    ].map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>   
                            </Grid>                     
                            <Grid item xs={11} sm={3}>
                              <Button variant="contained"  color="success" type="button" onClick={formikLang.handleSubmit}>
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                  <LangTable data={listlangdata} handleEdit={handleEdit} handleDelete={handleDelete}   />


                  <h2>Extra Training</h2>
                  <Button onClick={handleOpenTrain} variant="outlined">Add</Button>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openAddTrain}
                    onClose={handleCloseTrain}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >

                    <Fade in={openAddTrain}>
                      <Box className='popupaddedit'>
                        <Typography id="transition-modal-title" variant="h4" component="h2">
                          Add Training
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_name"
                                label="Training Program / Course Name"
                                value={formikTraining.values.new_name}
                                onChange={formikTraining.handleChange}
                                onBlur={formikTraining.handleBlur}
                                helperText={formikTraining.touched.new_name && formikTraining.errors.new_name}
                                error={ formikTraining.touched.new_name  && Boolean(formikTraining.errors.new_name)}
                                inputProps={{
                                  maxlength: 100
                                }}      
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_centerinstitutecompany"
                                label="Center/ Institute / Company"
                                value={formikTraining.values.new_centerinstitutecompany}
                                onChange={formikTraining.handleChange}
                                onBlur={formikTraining.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikTraining.touched.new_centerinstitutecompany && formikTraining.errors.new_centerinstitutecompany}
                                error={ formikTraining.touched.new_centerinstitutecompany  && Boolean(formikTraining.errors.new_centerinstitutecompany)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_periodhours"
                                label="Period (Hours)"
                                value={formikTraining.values.new_periodhours}
                                onChange={formikTraining.handleChange}
                                onBlur={formikTraining.handleBlur}
                                inputProps={{
                                  maxlength: 100
                                }}
                                helperText={formikTraining.touched.new_periodhours && formikTraining.errors.new_periodhours}
                                error={ formikTraining.touched.new_periodhours  && Boolean(formikTraining.errors.new_periodhours)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                value={ formikTraining.values.new_fromdate || null}
                                onChange={(date) => formikTraining.setFieldValue('new_fromdate',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikTraining.touched.new_fromdate  && Boolean(formikTraining.errors.new_fromdate),
                                    helperText:formikTraining.touched.new_fromdate && formikTraining.errors.new_fromdate
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formikTraining.values.new_todate || null}
                                onChange={(date) => formikTraining.setFieldValue('new_todate',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikTraining.touched.new_todate  && Boolean(formikTraining.errors.new_todate),
                                    helperText:formikTraining.touched.new_todate && formikTraining.errors.new_todate
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_descriptionobjectivecontentacquiredskills"
                                label="Description"
                                value={formikTraining.values.new_descriptionobjectivecontentacquiredskills}
                                onChange={formikTraining.handleChange}
                                onBlur={formikTraining.handleBlur}
                                inputProps={{
                                  maxlength: 2000
                                }}
                                helperText={formikTraining.touched.new_descriptionobjectivecontentacquiredskills && formikTraining.errors.new_descriptionobjectivecontentacquiredskills}
                                error={ formikTraining.touched.new_descriptionobjectivecontentacquiredskills  && Boolean(formikTraining.errors.new_descriptionobjectivecontentacquiredskills)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
 
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formikTraining.handleSubmit}>
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                  <ExtraTrainTable data={listextratraindata} handleEdit={handleEdit} handleDelete={handleDelete}   /> 
                  
                 </div>
              )}
              {activeStep === 3 && (
                <>
                 <h4>Corporate Records</h4>
                 <Grid container spacing={2}>   
                    <Grid item xs={12} sm={5}>
                        <TextField required
                          name="new_haveyoueverbeenappliedforrowadbefore"
                          select
                         autoFocus
                          label="Have you applied before for vacancies in Rowad?"
                          value={formikBackgroundVerif.values.new_haveyoueverbeenappliedforrowadbefore}
                          onChange={
                            (value) => {
                    
                              if(value.target.value){
                                formikBackgroundVerif.setFieldValue('new_haveyoueverbeenappliedforrowadbefore',  true )
                          
                              }  else {
                                formikBackgroundVerif.setFieldValue('new_haveyoueverbeenappliedforrowadbefore',  false )
                                formikBackgroundVerif.setFieldValue('new_ifyespleasegivedetails','')
                              }
                            }
                          }
                          fullWidth
                          margin="normal"
                          onBlur={formikBackgroundVerif.handleBlur}
                          defaultValue={null}
                          helperText={formikBackgroundVerif.touched.new_haveyoueverbeenappliedforrowadbefore && formikBackgroundVerif.errors.new_haveyoueverbeenappliedforrowadbefore}
                          error={ formikBackgroundVerif.touched.new_haveyoueverbeenappliedforrowadbefore  && Boolean(formikBackgroundVerif.errors.new_haveyoueverbeenappliedforrowadbefore)}
                          id="new_haveyoueverbeenappliedforrowadbefore"
                        >{
                          [
                            {value:true,name:"Yes"},
                            {value:false,name:"No"}
                          ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>   
                    </Grid>   
                    {formikBackgroundVerif.values.new_haveyoueverbeenappliedforrowadbefore &&(           
                        <>            
                    <Grid item xs={11} sm={5}>
                    <TextField 
                          
                          name="new_ifyespleasegivedetails"
                          id="new_ifyespleasegivedetails"
                          // autoFocus 
                          required
                          helperText={formikBackgroundVerif.touched.new_ifyespleasegivedetails && formikBackgroundVerif.errors.new_ifyespleasegivedetails}
                          error={ formikBackgroundVerif.touched.new_ifyespleasegivedetails  && Boolean(formikBackgroundVerif.errors.new_ifyespleasegivedetails)}
                          label="If Yes Please give details When Where How Result"
                          value={formikBackgroundVerif.values.new_ifyespleasegivedetails}
                          onChange={formikBackgroundVerif.handleChange}
                          onBlur={formikBackgroundVerif.handleBlur}
                          inputProps={{
                            maxlength: 2000
                          }}
                          fullWidth
                          margin="normal"
                          />
                    </Grid>
                    </>
                    )}
                 </Grid>
                 <Grid container spacing={2}>     
                      <Grid item xs={11} sm={5}>
                          <TextField required
                            name="new_haveyoueverbeenemployedbyrowad"
                            select

                            label="Have you been employed before by Rowad?"
                            value={formikBackgroundVerif.values.new_haveyoueverbeenemployedbyrowad}
                            onChange={
                              (value) => {
                                if(value.target.value){
                                  formikBackgroundVerif.setFieldValue('new_haveyoueverbeenemployedbyrowad',  true )    
                                }  else {
                                  formikBackgroundVerif.setFieldValue('new_haveyoueverbeenemployedbyrowad',  false )
                                  formikBackgroundVerif.setFieldValue('new_ifyespleasegivedetailswhowherewhen','')
                                  formikBackgroundVerif.setFieldValue('new_crsite','')
                              
                                }
                              }
                            }
                            fullWidth
                            margin="normal"
                            onBlur={formikBackgroundVerif.handleBlur}
                            helperText={formikBackgroundVerif.touched.new_haveyoueverbeenemployedbyrowad && formikBackgroundVerif.errors.new_haveyoueverbeenemployedbyrowad}
                            error={ formikBackgroundVerif.touched.new_haveyoueverbeenemployedbyrowad  && Boolean(formikBackgroundVerif.errors.new_haveyoueverbeenemployedbyrowad)}
                            id="new_haveyoueverbeenemployedbyrowad"
                          >{
                            [
                              {value:true,name:"Yes"},
                              {value:false,name:"No"}
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>   
                      </Grid>  
                      {formikBackgroundVerif.values.new_haveyoueverbeenemployedbyrowad &&(           
                        <>
                          <Grid item xs={11} sm={3}>
                            <TextField 
                                  required
                                  name="new_ifyespleasegivedetailswhowherewhen"
                                  id="new_ifyespleasegivedetailswhowherewhen"
                                  // autoFocus 
                                  helperText={formikBackgroundVerif.touched.new_ifyespleasegivedetailswhowherewhen && formikBackgroundVerif.errors.new_ifyespleasegivedetailswhowherewhen}
                                  error={ formikBackgroundVerif.touched.new_ifyespleasegivedetailswhowherewhen  && Boolean(formikBackgroundVerif.errors.new_ifyespleasegivedetailswhowherewhen)}
                                  label="Position"
                                  value={formikBackgroundVerif.values.new_ifyespleasegivedetailswhowherewhen}
                                  onChange={formikBackgroundVerif.handleChange}
                                  onBlur={formikBackgroundVerif.handleBlur}
                                  inputProps={{
                                    maxlength: 2000
                                  }}
                                  fullWidth
                                  margin="normal"
                                  />
                          </Grid>
                          <Grid item xs={11} sm={3}>
                            <TextField 
                                  required
                                  name="new_crsite"
                                  id="new_crsite"
                                  // autoFocus 
                                  helperText={formikBackgroundVerif.touched.new_crsite && formikBackgroundVerif.errors.new_crsite}
                                  error={ formikBackgroundVerif.touched.new_crsite  && Boolean(formikBackgroundVerif.errors.new_crsite)}
                                  label="Site"
                                  value={formikBackgroundVerif.values.new_crsite}
                                  inputProps={{
                                    maxlength: 100
                                  }}
                                  onChange={formikBackgroundVerif.handleChange}
                                  onBlur={formikBackgroundVerif.handleBlur}
                                  fullWidth
                                  margin="normal"
                                  />
                          </Grid>
                        </>
                      )}
                 </Grid>
                 <Grid container spacing={2}>  
                 <Grid item xs={11} sm={5}>
                    <TextField required
                      name="new_doyouhaveanyrelativeshiredbyanyofrowadco"
                      select
                      label="Do you have any relatives employed by Rowad or any of its subsidiaries?"
                      value={formikBackgroundVerif.values.new_doyouhaveanyrelativeshiredbyanyofrowadco}
                      onChange={
                        (value) => {
                
                          if(value.target.value){
                            formikBackgroundVerif.setFieldValue('new_doyouhaveanyrelativeshiredbyanyofrowadco',  true )
                       
                          }  else {
                            formikBackgroundVerif.setFieldValue('new_doyouhaveanyrelativeshiredbyanyofrowadco',  false )
                            formikBackgroundVerif.setFieldValue('new_ifyouwillingtotravel','')
                          }
                        }
                      }
                      fullWidth
                      margin="normal"
                      onBlur={formikBackgroundVerif.handleBlur}
                      helperText={formikBackgroundVerif.touched.new_doyouhaveanyrelativeshiredbyanyofrowadco && formikBackgroundVerif.errors.new_doyouhaveanyrelativeshiredbyanyofrowadco}
                      error={ formikBackgroundVerif.touched.new_doyouhaveanyrelativeshiredbyanyofrowadco  && Boolean(formikBackgroundVerif.errors.new_doyouhaveanyrelativeshiredbyanyofrowadco)}
                      id="new_doyouhaveanyrelativeshiredbyanyofrowadco"
                    >{
                      [
                        {value:true,name:"Yes"},
                        {value:false,name:"No"}
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>   
                  </Grid>
                  {formikBackgroundVerif.values.new_doyouhaveanyrelativeshiredbyanyofrowadco &&(           
                        <>
                      <Grid item xs={11} sm={5}>
                      <TextField 
                            required
                            name="new_ifyespleasegivedetailswhowherewhenrelativ"
                            id="new_ifyespleasegivedetailswhowherewhenrelativ"
                            // autoFocus 
                            inputProps={{
                              maxlength: 500
                            }}
                            helperText={formikBackgroundVerif.touched.new_ifyespleasegivedetailswhowherewhenrelativ && formikBackgroundVerif.errors.new_ifyespleasegivedetailswhowherewhenrelativ}
                            error={ formikBackgroundVerif.touched.new_ifyespleasegivedetailswhowherewhenrelativ  && Boolean(formikBackgroundVerif.errors.new_ifyespleasegivedetailswhowherewhenrelativ)}
                            label="If Yes Please give details When Where How Result"
                            value={formikBackgroundVerif.values.new_ifyespleasegivedetailswhowherewhenrelativ}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      </>
                  )}
                 </Grid>

                 <h4>Medical Check</h4>                   
                 <Grid container spacing={2}>                  
                 <Grid item xs={11} sm={4}>
                        {/* <FormControlLabel control={
                        <Checkbox 
                        id="new_didyouoperateanysurgeries"
                        value={formikBackgroundVerif.values.new_didyouoperateanysurgeries}
                        onChange={(event) => {
                          const value = event.target.checked ? "Yes" : "No";
                          formikBackgroundVerif.setFieldValue("new_didyouoperateanysurgeries", value);
                        }}
                        checked={formikBackgroundVerif.values.new_didyouoperateanysurgeries==="Yes"}
                        onBlur={formikBackgroundVerif.handleBlur}
                         />} 
                        label="Did you operate any surgeries ? " /> */}
                        <TextField required
                        name="new_didyouoperateanysurgeries"
                        select
                        label="Did you operate any surgeries ?"
                        value={formikBackgroundVerif.values.new_didyouoperateanysurgeries}
                        onChange={
                          (value) => {
                  
                            if(value.target.value==="Yes"){
                              formikBackgroundVerif.setFieldValue('new_didyouoperateanysurgeries',  "Yes" )
                        
                            }  else {
                              formikBackgroundVerif.setFieldValue('new_didyouoperateanysurgeries',  "No" )
                              // formikBackgroundVerif.setFieldValue('new_ifyouwillingtotravel','')
                            }
                          } 
                        }
                        fullWidth
                        margin="normal"
                        onBlur={formikBackgroundVerif.handleBlur}
                        helperText={formikBackgroundVerif.touched.new_didyouoperateanysurgeries && formikBackgroundVerif.errors.new_didyouoperateanysurgeries}
                        error={ formikBackgroundVerif.touched.new_didyouoperateanysurgeries  && Boolean(formikBackgroundVerif.errors.new_didyouoperateanysurgeries)}
                        id="new_didyouoperateanysurgeries"
                      >{
                        [
                          {value:"Yes",name:"Yes"},
                          {value:"No",name:"No"}
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField> 
                 </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField required
                        name="new_doyouhaveanychronicdisease"
                        select
                        label="Do you receive any chronic medications?"
                        value={formikBackgroundVerif.values.new_doyouhaveanychronicdisease}
                        onChange={
                          (value) => {
                  
                            if(value.target.value==="Yes"){
                              formikBackgroundVerif.setFieldValue('new_doyouhaveanychronicdisease',  "Yes" )
                        
                            }  else {
                              formikBackgroundVerif.setFieldValue('new_doyouhaveanychronicdisease',  "No" )
                              // formikBackgroundVerif.setFieldValue('new_ifyouwillingtotravel','')
                            }
                          }
                        }
                        fullWidth
                        margin="normal"
                        onBlur={formikBackgroundVerif.handleBlur}
                        helperText={formikBackgroundVerif.touched.new_doyouhaveanychronicdisease && formikBackgroundVerif.errors.new_doyouhaveanychronicdisease}
                        error={ formikBackgroundVerif.touched.new_doyouhaveanychronicdisease  && Boolean(formikBackgroundVerif.errors.new_doyouhaveanychronicdisease)}
                        id="new_doyouhaveanychronicdisease"
                      >{
                        [
                          {value:"Yes",name:"Yes"},
                          {value:"No",name:"No"}
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField> 
                      </Grid>
                      <Grid item xs={11} sm={4}>
                      {formikBackgroundVerif.values.new_doyouhaveanychronicdisease==="Yes" &&( 
                      <TextField    
                      required                  
                            label="If yes, please state"
                            name="new_ifyespleasestate"
                            id="new_ifyespleasestate"
                            // autoFocus 
                            helperText={formikBackgroundVerif.touched.new_ifyespleasestate && formikBackgroundVerif.errors.new_ifyespleasestate}
                            error={ formikBackgroundVerif.touched.new_ifyespleasestate  && Boolean(formikBackgroundVerif.errors.new_ifyespleasestate)}
                            inputProps={{
                              maxlength: 100
                            }}
                            value={formikBackgroundVerif.values.new_ifyespleasestate}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}
                            fullWidth
                            margin="normal"
                            />
                      )}
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <FormControlLabel  control={
                        <Checkbox 
                        value={formikBackgroundVerif.values.new_iherebystatethatimmedicallyfit}
                        onChange={(event) => {
                          const value = event.target.checked ? "Yes" : "No";
                          formikBackgroundVerif.setFieldValue("new_iherebystatethatimmedicallyfit", value);
                        }}
                        checked={formikBackgroundVerif.values.new_iherebystatethatimmedicallyfit==="Yes"}
                        />} 
                        
                        label="I hereby state that I’m medically fit and free from infectious diseases and all that may hinder the implementation of the contractual tasks." />
                      <div style={{color: "red"}}>{formikBackgroundVerif.errors.new_iherebystatethatimmedicallyfit}</div>
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <FormControlLabel  control={
                        <Checkbox id="new_iconfirmiamawarethatimaybesubject"
                        value={formikBackgroundVerif.values.new_iconfirmiamawarethatimaybesubject}
                        onChange={(event) => {
                          const value = event.target.checked ? "Yes" : "No";
                          formikBackgroundVerif.setFieldValue("new_iconfirmiamawarethatimaybesubject", value);
                        }}
                        checked={formikBackgroundVerif.values.new_iconfirmiamawarethatimaybesubject==="Yes"}
                        />} 
                        label="I confirm I am aware that I may be subject, upon the employer’s request, to medical Examinations  before or during work to ensure that I am free from occupational or communicable diseases." />
                      <div style={{color: "red"}}>{formikBackgroundVerif.errors.new_iconfirmiamawarethatimaybesubject}</div>
      
                      </Grid>
                      <Grid item xs={11} sm={4}>
                        <FormControlLabel  control={
                        <Checkbox id="new_iundertaketosubmitalltherequiredemploymen" 
                        value={formikBackgroundVerif.values.new_iundertaketosubmitalltherequiredemploymen}
                        onChange={(event) => {
                          const value = event.target.checked ? "Yes" : "No";
                          formikBackgroundVerif.setFieldValue("new_iundertaketosubmitalltherequiredemploymen", value);
                        }}
                        checked={formikBackgroundVerif.values.new_iundertaketosubmitalltherequiredemploymen==="Yes"}
 
                        />}
                        label="I undertake to submit all the  employment documents including attested educational and experience certificates as per the applicable rules and regulations." 

                        />
                        <div style={{color: "red"}}>{formikBackgroundVerif.errors.new_iundertaketosubmitalltherequiredemploymen}</div>
                      </Grid>
                      
                 </Grid>
                 <h4>1st Reference Check</h4>
                 <Grid container spacing={2}>                  
                      <Grid item xs={11} sm={3}>
                        <TextField 
                              required
                              name="new_fullname"
                              id="new_fullname"
                              // autoFocus 
                              helperText={formikBackgroundVerif.touched.new_fullname && formikBackgroundVerif.errors.new_fullname}
                              error={ formikBackgroundVerif.touched.new_fullname  && Boolean(formikBackgroundVerif.errors.new_fullname)}
                              label="Full Name"
                              value={formikBackgroundVerif.values.new_fullname}
                              onChange={formikBackgroundVerif.handleChange}
                              onBlur={formikBackgroundVerif.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
                              fullWidth
                              margin="normal"
                              />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                              required
                              name="new_company"
                              id="new_company"
                              // autoFocus 
                              helperText={formikBackgroundVerif.touched.new_company && formikBackgroundVerif.errors.new_company}
                              error={ formikBackgroundVerif.touched.new_company  && Boolean(formikBackgroundVerif.errors.new_company)}
                              label="Company"
                              inputProps={{
                                maxlength: 100
                              }}
                              value={formikBackgroundVerif.values.new_company}
                              onChange={formikBackgroundVerif.handleChange}
                              onBlur={formikBackgroundVerif.handleBlur}
                              fullWidth
                              margin="normal"
                              />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                              required
                              name="new_relation"
                              id="new_relation"
                              // autoFocus 
                              helperText={formikBackgroundVerif.touched.new_relation && formikBackgroundVerif.errors.new_relation}
                              error={ formikBackgroundVerif.touched.new_relation  && Boolean(formikBackgroundVerif.errors.new_relation)}
                              label="Relation"
                              value={formikBackgroundVerif.values.new_relation}
                              onChange={formikBackgroundVerif.handleChange}
                              onBlur={formikBackgroundVerif.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
                              fullWidth
                              margin="normal"
                              />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                            required
                            name="new_position"
                            id="new_position"
                            // autoFocus 
                            helperText={formikBackgroundVerif.touched.new_position && formikBackgroundVerif.errors.new_position}
                            error={ formikBackgroundVerif.touched.new_position  && Boolean(formikBackgroundVerif.errors.new_position)}
                            label="Position"
                            value={formikBackgroundVerif.values.new_position}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}                       
                            inputProps={{
                              maxlength: 100
                            }}
                            fullWidth
                            margin="normal"
                        />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                            required
                            name="new_contactphone"
                            id="new_contactphone"
                            // autoFocus 
                            helperText={formikBackgroundVerif.touched.new_contactphone && formikBackgroundVerif.errors.new_contactphone}
                            error={ formikBackgroundVerif.touched.new_contactphone  && Boolean(formikBackgroundVerif.errors.new_contactphone)}
                            label="Contact Phone"
                            value={formikBackgroundVerif.values.new_contactphone}
                            onChange={formikBackgroundVerif.handleChange}
                            onInput={(e) => {
                              let numericValue = e.target.value;
                              numericValue = numericValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                           
                              // Check if the numeric value exceeds the max value (99999999)
                              if (numericValue.length > 20) {
                                numericValue = numericValue.slice(0, 20); // Truncate the value to 8 digits
                              }
                          
                              e.target.value = numericValue; // Assign the updated value back to the input field
                            }}
                            onBlur={formikBackgroundVerif.handleBlur}

                            fullWidth
                            onKeyDown={(e) => {
                              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                e.preventDefault()
                              }
                            }}
                            margin="normal"
                        />
                       </Grid>
                 </Grid>
                 <h4>2nd Reference Check</h4>
                 <Grid container spacing={2}>                  
                      <Grid item xs={11} sm={3}>
                        <TextField 
                              required
                              name="new_new_rf2fullname"
                              id="new_new_rf2fullname"
                              // autoFocus 
                              helperText={formikBackgroundVerif.touched.new_new_rf2fullname && formikBackgroundVerif.errors.new_new_rf2fullname}
                              error={ formikBackgroundVerif.touched.new_new_rf2fullname  && Boolean(formikBackgroundVerif.errors.new_new_rf2fullname)}
                              label="Full Name"
                              value={formikBackgroundVerif.values.new_new_rf2fullname}
                              onChange={formikBackgroundVerif.handleChange}
                              onBlur={formikBackgroundVerif.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
                              fullWidth
                              margin="normal"
                              />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                      <TextField 
                            name="new_rf2company"
                            id="new_rf2company"
                            required
                            helperText={formikBackgroundVerif.touched.new_rf2company && formikBackgroundVerif.errors.new_rf2company}
                            error={ formikBackgroundVerif.touched.new_rf2company  && Boolean(formikBackgroundVerif.errors.new_rf2company)}
                            label="Company"
                            value={formikBackgroundVerif.values.new_rf2company}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}
                            inputProps={{
                              maxlength: 100
                            }}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                              required
                              name="new_rf2relation"
                              id="new_rf2relation"
                              // autoFocus 
                              helperText={formikBackgroundVerif.touched.new_rf2relation && formikBackgroundVerif.errors.new_rf2relation}
                              error={ formikBackgroundVerif.touched.new_rf2relation  && Boolean(formikBackgroundVerif.errors.new_rf2relation)}
                              label="Relation"
                              value={formikBackgroundVerif.values.new_rf2relation}
                              onChange={formikBackgroundVerif.handleChange}
                              onBlur={formikBackgroundVerif.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
                              fullWidth
                              margin="normal"
                              />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField 
                            required
                            name="new_rf2position"
                            id="new_rf2position"
                            // autoFocus 
                            helperText={formikBackgroundVerif.touched.new_rf2position && formikBackgroundVerif.errors.new_rf2position}
                            error={ formikBackgroundVerif.touched.new_rf2position  && Boolean(formikBackgroundVerif.errors.new_rf2position)}
                            label="Position"
                            value={formikBackgroundVerif.values.new_rf2position}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}
                            inputProps={{
                              maxlength: 100
                            }}
                            fullWidth
                            margin="normal"
                        />
                      </Grid>
                      <Grid item xs={11} sm={2}>
                        <TextField    
                            name="new_rf2contactphone"
                            id="new_rf2contactphone"
                            required 
                            helperText={formikBackgroundVerif.touched.new_rf2contactphone && formikBackgroundVerif.errors.new_rf2contactphone}
                            error={ formikBackgroundVerif.touched.new_rf2contactphone  && Boolean(formikBackgroundVerif.errors.new_rf2contactphone)}
                            label="Contact Phone"
                            value={formikBackgroundVerif.values.new_rf2contactphone}
                            onChange={formikBackgroundVerif.handleChange}
                            onBlur={formikBackgroundVerif.handleBlur}
           
                            fullWidth
                            onInput={(e) => {
                              let numericValue = e.target.value;
                              numericValue = numericValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                           
                              // Check if the numeric value exceeds the max value (99999999)
                              if (numericValue.length > 20) {
                                numericValue = numericValue.slice(0, 20); // Truncate the value to 8 digits
                              }
                          
                              e.target.value = numericValue; // Assign the updated value back to the input field
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                e.preventDefault()
                              }
                            }}
                            margin="normal"
                        />
                       </Grid>
                 </Grid>


                </>
              )}
              {activeStep === 4 && (
                <> 
                {formikGeneral.values.new_availabletotravel &&(
                  <>
                 <h4>Passport </h4>
                 <Grid container spacing={2}>    
                      <Grid item xs={11} sm={4}>
                        <TextField 
                            name="new_passportnumber"
                            id="new_passportnumber"
                            // autoFocus 
                            label="Passport ID"
                            value={formikAdditionalInfo.values.new_passportnumber}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}
                            inputProps={{
                              maxlength: 100
                            }}

                            fullWidth
                            margin="normal"
                        />
                      </Grid>              
                      <Grid item xs={11} sm={4}>
                        <TextField  
                              name="new_issuingplace"
                              id="new_issuingplace"
                              // autoFocus 
                              label="Issuing Place"
                              value={formikAdditionalInfo.values.new_issuingplace}
                              onChange={formikAdditionalInfo.handleChange}
                              onBlur={formikAdditionalInfo.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
  
                              fullWidth
                              margin="normal"
                          />
                       </Grid>
                      <Grid item xs={11} sm={4}>
                        <TextField 
                              name="new_professioninpassport"
                              id="new_professioninpassport"
                              // autoFocus 
                              label="Profession In Passport"
                              value={formikAdditionalInfo.values.new_professioninpassport}
                              onChange={formikAdditionalInfo.handleChange}
                              onBlur={formikAdditionalInfo.handleBlur}
                              inputProps={{
                                maxlength: 100
                              }}
                              fullWidth
                              margin="normal"
                          />
                      </Grid>
                      <Grid item xs={11} sm={4}> 
                        <LocalizationProvider  dateAdapter={AdapterDayjs} >
                          <DemoContainer  components={['DatePicker']} >
                            <DatePicker
                            label="Issuing Date"
                            className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                            value={ formikAdditionalInfo.values.new_issuingdate || null}
                            onChange={(date) => formikAdditionalInfo.setFieldValue('new_issuingdate',   date )}
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                // format:'YYYY-MM-DD',
                                disabled:true,
                              },
                              }}
                              />
                            </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={11} sm={4}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                          <DemoContainer  components={['DatePicker']} >
                            <DatePicker
                            label="Expiry Date"
                            className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                            value={ formikAdditionalInfo.values.new_psprtexpirydate || null}
                            onChange={(date) => formikAdditionalInfo.setFieldValue('new_psprtexpirydate',  date )}
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                // format:'YYYY-MM-DD',
                                disabled:true,
                              },
                              }}
                              />
                            </DemoContainer>
                        </LocalizationProvider>
                      </Grid> 
 
                 </Grid>
                 </>
                )} 
                {formikGeneral.values.new_doyoupossessavaliddrivinglicense &&(
                  <>
                 <h4>Driving License </h4> 
                 <Grid container spacing={2}>                  
                      <Grid item xs={11} sm={3}>
                      <TextField 
                            name="new_drivinglicensenumber"
                            id="new_drivinglicensenumber"
                            label="Driving License Number"
                            value={formikAdditionalInfo.values.new_name}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}
                            fullWidth
                            margin="normal"
                            inputProps={{
                              maxlength: 20
                            }}
                            />
                      </Grid>
                      <Grid item xs={11} sm={4}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                          <DemoContainer  components={['DatePicker']} >
                            <DatePicker
                            label="Expiry Date"
                            className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                            value={ formikAdditionalInfo.values.new_dlexpirydate || null}
                            onChange={(date) => formikAdditionalInfo.setFieldValue('new_dlexpirydate',  date )}
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                // format:'YYYY-MM-DD',
                                disabled:true,
                              },
                              }}
                              />
                            </DemoContainer>
                        </LocalizationProvider>
                      </Grid> 
                 </Grid>
                 </>
                )}
                                 <h4>Achievements</h4>
                 <Grid container spacing={2}>                  
                      <Grid item xs={11} sm={12}>
                        <p>List out the extra social activities / Hobbies / Sports that you have ever participated in. Describe briefly the range of your responsibilities / Involvement in these Activities . Please List Honors, Awards, Or Recognition (Academic, Sportive, Community, ..etc.) You have ever received</p>
                        <TextField 
                            
                            multiline
                            minRows={2}
                            name="new_listouttheextrasocialactivities"
                            id="new_listouttheextrasocialactivities"
                            // autoFocus 
                            helperText={formikAdditionalInfo.touched.new_listouttheextrasocialactivities && formikAdditionalInfo.errors.new_listouttheextrasocialactivities}
                            error={ formikAdditionalInfo.touched.new_listouttheextrasocialactivities  && Boolean(formikAdditionalInfo.errors.new_listouttheextrasocialactivities)}
 
                            value={formikAdditionalInfo.values.new_listouttheextrasocialactivities}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}                 
                            inputProps={{
                              maxlength: 2000
                            }}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={12}>
                        <p>What quality traits do you possess that you believe will help you perform as a rowad employee?. , Under What Capacities / Circumstances have you demonstrated these traits?</p>
                        <TextField 
                            
                            multiline
                            minRows={2}
                            name="new_qualitiesattributes"
                            id="new_qualitiesattributes"
                            // autoFocus 
             
                            value={formikAdditionalInfo.values.new_qualitiesattributes}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}                        
                            inputProps={{
                              maxlength: 2000
                            }}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={6}>
                        <p>Describe both your short and long term career goals. Indicate how your career at rowad will help you attain your goals.</p>
                        <TextField 
                            
                            multiline
                            minRows={4}
                            name="new_careergoals"
                            id="new_careergoals"
                            // autoFocus 
                            helperText={formikAdditionalInfo.touched.new_careergoals && formikAdditionalInfo.errors.new_careergoals}
                            error={ formikAdditionalInfo.touched.new_careergoals  && Boolean(formikAdditionalInfo.errors.new_careergoals)}
 
                            value={formikAdditionalInfo.values.new_careergoals}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}
                            inputProps={{
                              maxlength: 2000
                            }}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={6}>
                        <p>Please List any additional information that will support your application for employment</p>
                        <TextField 
                            
                            multiline
                            minRows={4}
                            name="new_additionalinformation"
                            id="new_additionalinformation"
                            // autoFocus 
                            helperText={formikAdditionalInfo.touched.new_additionalinformation && formikAdditionalInfo.errors.new_additionalinformation}
                            error={ formikAdditionalInfo.touched.new_additionalinformation  && Boolean(formikAdditionalInfo.errors.new_additionalinformation)}
 
                            value={formikAdditionalInfo.values.new_additionalinformation}
                            onChange={formikAdditionalInfo.handleChange}
                            onBlur={formikAdditionalInfo.handleBlur}
                            inputProps={{
                              maxlength: 2000
                            }}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      
                 </Grid>
                 <h4>Military Service </h4> 
                 <Grid container spacing={2}>    
                      <Grid item xs={11} sm={4}> 
                        <LocalizationProvider  dateAdapter={AdapterDayjs} >
                          <DemoContainer  components={['DatePicker']} >
                            <DatePicker
                            label="From"
                            className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                            value={ formikAdditionalInfo.values.new_from || null}
                            onChange={(date) =>
                             {
                              // if (date && !isNaN(date)) {
                                formikAdditionalInfo.setFieldValue('new_from', date);
                              // } else {
                              //   formikAdditionalInfo.setFieldValue('new_from', null);
                              // }
                             }
                              }
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                // format:'YYYY-MM-DD',
                                disabled:true,
                              },
                              }}
                              />
                            </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={11} sm={4}> 
                      <LocalizationProvider  dateAdapter={AdapterDayjs} >
                          <DemoContainer  components={['DatePicker']} >
                            <DatePicker
                            label="To"
                            className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                            value={ formikAdditionalInfo.values.new_to || null}
                          onChange={(date) => formikAdditionalInfo.setFieldValue('new_to',  date )}
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                // format:'YYYY-MM-DD',
                                disabled:true,
                              },
                              }}
                              />
                            </DemoContainer>
                        </LocalizationProvider>
                      </Grid> 
                      <Grid item xs={11} sm={4}>
                          <TextField 
                            name="new_doyouhaveanyfurthermilitaryobligations"
                            select
                            label="Do you have any further military obligations?"
                            value={formikAdditionalInfo.values.new_doyouhaveanyfurthermilitaryobligations}
                            onChange={formikAdditionalInfo.handleChange}
                            fullWidth
                            margin="normal"
                            onBlur={formikAdditionalInfo.handleBlur}
                            id="new_doyouhaveanyfurthermilitaryobligations"
                          >{
                            [
                              {value:true,name:"Yes"},
                              {value:false,name:"No"}
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField> 
                    </Grid> 
    
                 </Grid>
                 <h4><span style={{color:'red',paddingRight:'5px'}}>*</span>Upload CV / Resume</h4>
                 <Grid container spacing={2}>                  
                    <Grid item xs={11} sm={3}>
                    <input
                  type="file"
                  name="attachment"
                  onChange={(event) => {   
                    formikAdditionalInfo.setFieldValue('attachment', event.target.files[0]);
                  }}
                  onBlur={() => {}}
                  accept=".pdf"
                /> 
                 <>
                  <div className='Error'>{formikAdditionalInfo.errors.attachment}</div>
                  <br/>
                 </>
                    </Grid>
                  </Grid>

    
                      <HCaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        helperText={formikAdditionalInfo.touched.new_contactphone && formikAdditionalInfo.errors.new_contactphone}
                        error={ formikAdditionalInfo.touched.new_contactphone  && Boolean(formikAdditionalInfo.errors.new_contactphone)} 
                        onVerify={(value) => {      
                          formikAdditionalInfo.setFieldValue("gtoken", value);
                          formikAdditionalInfo.setSubmitting(false);
                        }}
                        onExpire={()=>formikAdditionalInfo.setFieldValue("gtoken", "")}
                        />
                       <>
                 <div className='Error'>{formikAdditionalInfo.errors.gtoken}</div> </>
  
                </>
              )}     
              <div style={{ display: 'flex', justifyContent: 'space-between',paddingTop:'2%' }}>   
 
                {activeStep > 0 &&
                <Button variant="contained" onClick={handleBack}  >
                  Back
                </Button>
                }
                {activeStep < steps.length-1 && 
                <Button variant="contained"
                onClick={handleNextSubmit} >
                  Next
                </Button>
                }
                {activeStep == steps.length-1  && 
                <>
                 {/* <ButtonGroup variant="outlined" aria-label="loading button group">
                  <LoadingButton variant="contained" 
                  onClick={handleNextSubmit}>
                    Submit
                  </LoadingButton>
                </ButtonGroup>  */}
                    <LoadingButton variant="contained"
                    loading={LoadingAPI}
                onClick={handleNextSubmit} >
                  Submit
                </LoadingButton>
                </>

                }
              </div> 
          </form>
        </Box>
      </Container>
  );
};

export default MegaProjectForm;   